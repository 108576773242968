import React, {useState} from 'react';
import styled from 'styled-components';
import {RectButton} from '../../Components/RectButton';
import {FlexColumnStartStart} from '../../widget';
import {INPUT_TYPES} from '../../constants';
import {useForm} from 'react-hook-form';
import {Input} from '../../Components/Input';
import {useOutlet} from 'reconnect.js';
import {navigate} from 'gatsby-link';
import useModal from '../../Hooks/useModal';
import {AlertModalContent} from '../../Components/AlertModalContent';

const LOGIN_FIELDS = [
  {
    placeholder: '請輸入會員帳號',
    name: 'username',
    defaultValue: '',
    rules: {
      required: true,
      pattern: /^09[0-9]{8}$/,
    },
    message: {
      required: '會員帳號不可為空',
      pattern: '手機格式錯誤',
    },
    label: '會員帳號',
    type: INPUT_TYPES.text,
  },
  {
    placeholder: '請輸入會員密碼',
    name: 'password',
    defaultValue: '',
    rules: {
      required: true,
      pattern: /^[A-Za-z0-9]{8,12}$/,
    },
    message: {
      required: '會員密碼不可為空',
      pattern: '密碼需為 8-12 位英數字組合',
    },
    label: '會員密碼',
    type: INPUT_TYPES.password,
  },
];

function LoginPage(props) {
  const [isLoading, setIsLoading] = useState(false);
  const [actions] = useOutlet('actions');
  const {modalElem, modalRef} = useModal();
  const {
    register,
    handleSubmit,
    formState: {errors},
  } = useForm();

  const onSubmit = async (data) => {
    const values = {
      MemberLogin: `user-${data.username}`,
      MemberPassword: data.password,
    };
    try {
      setIsLoading(true);
      await actions.login(values);
      await navigate('/profile');
    } catch (e) {
      modalRef.current.open();
      modalRef.current.set({
        elem: (
          <AlertModalContent
            title="登入失敗"
            subtitle="請確認帳號密碼後重新嘗試"
            onConfirm={() => {
              modalRef.current.close();
            }}
          />
        ),
      });
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Wrapper>
      <div className="view">
        {LOGIN_FIELDS.map((f) => (
          <Input
            style={{marginBottom: 10}}
            key={f.name}
            field={f}
            register={register(f.name, f.rules)}
            errors={errors}
          />
        ))}
        <div style={{flex: 1}} />
        <RectButton
          isLoading={isLoading}
          style={{width: '100%'}}
          onClick={handleSubmit(onSubmit)}>
          登入
        </RectButton>
      </div>
      {modalElem}
    </Wrapper>
  );
}

const Wrapper = styled.div`
  margin: var(--topNavBarHeight) auto 0 auto;
  max-width: var(--contentMaxWith);
  padding: var(--basePadding);
  display: flex;
  flex-direction: column;
  min-height: calc(100vh - var(--topNavBarHeight));
  & > .view {
    flex: 1;
    ${FlexColumnStartStart};
  }
`;

export default LoginPage;
